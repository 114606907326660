.saveColor{
    background: #415c7b;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 24px;
    background-color: #F5F5F5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header__logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .header__menu {
    display: flex;
    align-items: center;
  }
  
  .header__menu-item {
    margin-left: 24px;
    font-size: 16px;
    color: #555;
  }
.buttonCont{
    background: #264d82;
    color: white;
    font-weight: bold;
    border-radius: 9999px;
    padding: 6px 16px;
    margin: 4px;
    outline: 0px;
    border: none;
    cursor: pointer;
    font-size: calc(12px + 0.4vw);
    word-break: normal; 
}

.negativeBtn{
    background: #3D3D3B !important;
}

.goldBtn{
    background: #c3a870 !important;
}

.gradientBtn{
    background: linear-gradient(270deg, #c3a870 0%, #083470 71.09%) !important;
}

.smallPad{
    padding: 2px 16px !important;
}

.extraButtonStyle{
    border: 1px solid white;
    text-transform: uppercase;
}
.extraButtonCont{
    background: #3D3D3B;
    border-radius: 9999px;
    cursor: pointer;
}

.stopLink{
    text-decoration: none;
    color: white;
}

.headerMainCont{
    display: contents;
}

.remainingHeight{
    height: calc(100% - 300px);
    background-color: #f2f2f2;
}

@media only screen and (max-width: 600px) {
    .remainingHeight{
        height: calc(100% - 200px);
        background-color: #f2f2f2;
    }
}
