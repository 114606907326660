/* Set a primary color */
:root {
    --primary-color: #72c66e;
}

/* Use the primary color for buttons */
button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 4px;
    border-radius: 5px;
    cursor: pointer;
}

/* Add some spacing between elements */
.flexCont, .flexContStart, .formCont {
    /* Remove padding and margin */
    padding: 0;
    margin: 0;
}

.tWidth{
    width: 30%;
}

/* Style the main container */
.flexCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    padding-bottom: 16px;
}

.flexCont>*{
    margin: 4px;
}

/* Style the start-aligned container */
.flexContStart {
    align-items: flex-start;
}

/* Style the button container */
.flexButton {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.flexButtonMain, .flexButtonMainSedi {
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flexButtonMain>label:nth-child(3n-2)
{
    grid-column-start: 1;
    grid-column-end: 1;
}
.flexButtonMain>label:nth-child(3n-1)
{
    grid-column-start: 2;
    grid-column-end: 2;
}

.flexButtonMain>label:nth-child(3n)
{
    grid-column-start: 3;
    grid-column-end: 3;
}
.flexButtonCol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
}

.flexButton>button, .flexButton>label, .flexButtonMain>button, .flexButtonMain>label, .flexButtonCol>button, .flexButtonCol>label{
    margin: 4px;
}

/* Style the form container */
.formCont {
    display: contents   ;
    flex-direction: column;
    align-items: flex-end;
}

.gridWrapper{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backButton{
    position: absolute;
    top: 2%;
    right: 2%;
}

/* Style the input container */
.inputCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}
.subtitleCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

/* Style the labels for inputs */
label {
    font-weight: bold;
}

/* Style the input fields */
input,  select, option {
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #f9f9f9;
    font-size: 16px;
}

/* Add a hover effect to buttons */
button:hover {
    background-color: #2743a0;
}

/* Add a focus effect to input fields */
input:focus {
    outline: none;
    box-shadow: 0 0 5px var(--primary-color);
}


.fullWidth{
    width: auto;
}

.flexTitle{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.flexTitle>h2, .flexTitle>div{
    margin: 4px;
}



.flexModal{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalOverlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Style the modal */
  .modalStyle {
    border: 1px solid rgb(204, 204, 204);
    background: #ededed;
    padding: 20px;
    max-height: 660px;
    overflow: overlay;
  }

  .imgWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    height: 300px;
  }

  .logo{
    width: 300px;
  }

  .logout{
    position: absolute;
    top: 2%;
    left: 2%;
  }

  .filterCont{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }

  .filterCont>label{
    width: 25%;
    max-width: 240px;
  }
  
  .filterCont>label>div>div>input, .filterCont>label>select, .filterCont>label>select>option{
    width: 100%;
  }

  .earningsTable>div>table>tbody>tr>td{
    text-align: end!important;
  }

  .disabledButton{
    cursor:default;
    pointer-events: none;
    filter: saturate(0.1);
  }

  .buttonWrapper{
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filterWrapper{
    display: flex;
    flex-direction: column;
  }

  .labelWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .labelWrapper>select{
    width: 33%;
  }



/* Media query for mobile devices */

@media only screen and (max-width: 600px) {
    /* Reduce the margin on containers */
    .flexCont, .flexContStart, .formCont {
        margin: 0;
        padding: 0;
    }

    /* Make the main container full width */
    .flexCont {
        width: 100%;
    }

    /* Reduce the padding and font size on buttons */
    button {
        padding: 5px 10px;
        font-size: 14px;
    }

    /* Reduce the margin on input containers */
    .inputCont {
        margin: 5px 0;
    }

    /* Reduce the font size on labels and inputs */
    label, input, select{
        font-size: 14px;
    }
    select{
        width: 70%;
    }
    option{
        font-size: 10px;
    }

    .logo{
        width: 150px;
      }

    .imgWrap{
        height: 200px;
    }   
}

@media only screen and (max-width: 600px){

    .inputCont{
        display: flex;
        flex-direction: column;
    }

    .labelWrapper{
        flex-direction: column;
    }

    .fullWidth > label{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 10px 0;
    }
    .fullWidth > label >*{
        margin: 10px 0 0 0;
    }

    .tWidth{
        width: 40%;
    }
    
    .flexButtonMain{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .flexButtonMain>label:nth-child(2n-1)
{
    grid-column-start: 1;
    grid-column-end: 1;
}
.flexButtonMain>label:nth-child(2n)
{
    grid-column-start: 2;
    grid-column-end: 2;
}

}

@media only screen and (max-width: 360px){

    label>button{
        font-size: calc(8px + 0.6vw);
    }
    
    .flexButtonMain{
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .flexButtonMain>label:nth-child(2n-1)
{
    grid-column-start: 1;
    grid-column-end: 1;
}
.flexButtonMain>label:nth-child(2n)
{
    grid-column-start: 1;
    grid-column-end: 1;
}

}

@media only screen and (max-width: 1366px){
    
    .flexButtonMainSedi{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .flexButtonMainSedi>.inputCont{
        flex-direction: column;
    }
}