/* Set primary colors */
:root {
    --primary-color: #72c66e;
}

/* Style the main container */
.flexCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    padding: 30px;
    border-radius: 10px;
}

/* Style the logo */
.logo {
    width: 150px;
    margin-bottom: 30px;
}

/* Style the form container */
.formCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Style the input fields */
.inputCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
}

/* Style the labels for inputs */
label {
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--secondary-color);
}

.wideButton{
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 40px;
    cursor: pointer;
    width: 100%;
}


/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
    /* Reduce the margin on containers */
    .flexCont, .formCont {
        padding: 20px;
    }

    /* Make the main container full width */
    .flexCont {
        width: 100%;
    }

    /* Reduce the padding and font size on buttons */
    .button {
        padding: 5px 10px;
        font-size: 14px;
    }

    /* Reduce the margin on input containers */
    .inputCont {
        margin: 5px 0;
    }

    /* Reduce the font size on labels and inputs */
    label, input {
        font-size: 14px;
    }
}

.container{
    /* background-color: #f2f2f2; */
    /* background-color: white; */
    /* border-top-right-radius: 80px; */
    width: 35%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    /* max-width: 500px; */
}

.confirmCont{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.back{
    background-color: #f2f2f2;
}

form>div, form>h3{
    margin: 6px 0px 6px 0px;
}
