body {
  margin: 0;
  font-family: 'Inter-SemiBold', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter-SemiBold', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, span, h1, h2, h3, h4, h5, input, div, a, select, option, button {
  font-family:  'Inter-SemiBold', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Light';
  font-weight: 500;
  src: local('Inter'), url(./fonts/Inter-Light.ttf) format('opentype');
}

@font-face {
    font-family: 'Inter-Bold';
    font-weight: 400;
    src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Inter-Black';
    font-weight: 600;
    src: local('Inter'), url(./fonts/Inter-Black.ttf) format('opentype');
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #D9D9D9  #3D3D3B;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 6px;
  width: 6px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #3D3D3B;
}

*::-webkit-scrollbar-track:hover {
  background-color: #3D3D3B;
}

*::-webkit-scrollbar-track:active {
  background-color: #3D3D3B;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #D9D9D9;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #D9D9D9;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #D9D9D9;
}

html, body{
  height: 100vh;
}

#root{
  height: 100%;
}

.reactgrid-content{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
}

.reactgrid-content, .reactgrid{
  width: 90% !important;
  min-height: 10vh;
}