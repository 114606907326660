.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4, h5 {
  margin: 0;
  font-weight: 400;
  word-break: break-word;
}

h1{
  font-size: calc(16px + 1.5vw);
  font-weight: bold;
}

h3{
  font-size: calc(14px + 0.6vw);
}

th, td{
  font-size: calc(10px + 0.6vw)!important;
  font-family: 'Inter' !important;
  max-width: 75vw;
  border-right: 1px solid;
}

th{
  font-variant: petite-caps;
  font-weight: bold !important;
}


tr:nth-child(even){
    background-color: #dedede;
}
tr:nth-child(odd){
    background-color: #ffffff;
}
tr:nth-child(even)>td:first-child{
  background-color: #dedede;
  border-left: 1px solid;
}
tr:nth-child(odd)>td:first-child{
  background-color: #ffffff;
  border-left: 1px solid;
}
tr:first-child>th:first-child{
  border-left: 1px solid;
}

th{
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
}

td{
  padding: 0px !important;
  text-align: end!important;
}

.MuiPaper-root{
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}

input{
  font-family: Inter;
}

label>button{
  width: 100%;
  height: 100%;
  font-size: calc(6px + 0.6vw);
}

textarea{
  font-family: Inter;
  padding: 20px !important;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 20%);
}

.MuiTooltip-tooltip{
  background-color: #c3a870 !important;
}

.upload__image-wrapper{
  display: flex;
  flex-direction: column;
}

.image-item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image-item>img{
  border-radius: 30px 30px 30px 0px;
  border: 1px solid;
  object-fit: cover;
}

span{
  padding: 8px 0px;
}

.rg-header-cell{
  overflow: visible !important;
  white-space: normal !important;
  text-align: center;
  font-size: 0.6em !important;
}

.reactgrid-content .rg-pane .rg-cell{
  justify-content: center !important;
} 

.react-datepicker-wrapper{
  width: auto !important;
}
.react-datepicker-popper{
  z-index: 1200 !important;
}

.react-datepicker__navigation-icon--previous::before{
  transform: rotate(225deg) !important;
  right: 2px !important;
  top: 14px !important;
}

.react-datepicker__navigation-icon--next::before{
  transform: rotate(45deg) !important;
  left: 2px !important;
  top: 14px !important;
}